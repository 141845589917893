<template>
  <div id="claim" class="marketplace-claim__container">
    <div class="marketplace-claim">
      <h2 class="marketplace-claim__title">
        {{ country === "tr" ? "Bağlantı başvurusu" : "Заявка на подключение" }}
      </h2>
      <span class="marketplace-claim__switch">
        <SwitchButtonComponent :checked="country === 'tr'" @change="country = $event ? 'tr' : 'ru'">
          Ortaklık
        </SwitchButtonComponent>
      </span>
      <div class="marketplace-claim__tabs" v-if="country === 'tr'">
        <button
          v-for="(tab, i) in tabs"
          :key="i"
          class="marketplace-claim__tab"
          :class="{ 'marketplace-claim__tab--active': activeTab === tab.code }"
          @click="activeTab = tab.code"
        >
          {{ tab.title }}
        </button>
      </div>
      <form @submit.prevent="claimCreate" class="marketplace-claim__form form" v-if="country === 'ru'">
        <SelectComponent
          v-model="form.category_id.value"
          @input="saveToLocalStorage"
          :options="categories"
          :errors="form.category_id.error ? [form.category_id.error] : []"
          label-name="title"
          title="Выбор категории"
          selectTitle="Выбор категории"
        />
        <InputComponent
          v-model.trim="form.title.value"
          @input="saveToLocalStorage"
          :error="form.title.error"
          title="Наименование компании или магазина"
          placeholder="Наименование компании или магазина"
        />
        <InputComponent
          @input="saveToLocalStorage"
          v-model.trim="form.inn.value"
          :error="form.inn.error"
          title="ИНН"
          placeholder="ИНН"
        />
        <InputComponent
          v-model.trim="form.fio.value"
          @input="saveToLocalStorage"
          :error="form.fio.error"
          title="ФИО руководителя магазина"
          placeholder="ФИО руководителя магазина"
        />
        <InputComponent
          v-model.trim="form.email.value"
          @input="saveToLocalStorage"
          :error="form.email.error"
          title="Электронная почта"
          placeholder="Электронная почта"
        />
        <InputComponent
          v-model="form.phone.value"
          @input="saveToLocalStorage"
          :error="form.phone.error"
          title="Номер телефона"
          placeholder="Номер телефона"
          :mask="phoneMask"
        />
        <SelectComponent
          v-model="form.city_id.value"
          @input="saveToLocalStorage"
          :options="cities"
          :errors="form.city_id.error ? [form.city_id.error] : []"
          label-name="title"
          title="Город нахождения головного офиса"
          selectTitle="Город нахождения головного офиса"
        />
        <CheckboxComponent :checked="agree" @change="agree = !agree">
          <div>
            Согласен с условиями
            <a href="/files/terms.pdf" target="_blank">Правил пользования торговой площадкой</a> и
            <a href="/files/terms.pdf" target="_blank">правилами возврата</a>
          </div>
        </CheckboxComponent>
        <button type="submit" class="btn btn--lg btn--main" :disabled="!agree">
          <FileIconComponent v-if="loading" name="loading" />
          <template>{{ loadingInfo || "Отправить" }}</template>
        </button>
      </form>
      <form @submit.prevent="claimCreate" class="marketplace-claim__form form" v-if="country === 'tr'">
        <template v-if="activeTab === 1">
          <InputComponent
            v-model.trim="form.tr_name.value"
            @input="saveToLocalStorage"
            :error="form.tr_name.error"
            title="Adınız"
            placeholder="Adınız"
          />
          <InputComponent
            v-model.trim="form.tr_surname.value"
            @input="saveToLocalStorage"
            :error="form.tr_surname.error"
            title="Soyadınız"
            placeholder="Soyadınız"
          />
          <InputComponent
            v-model.trim="form.phone.value"
            @input="saveToLocalStorage"
            :error="form.phone.error"
            title="Cep Telefonunuz"
            placeholder="+90 845 721 88 98"
            :mask="phoneMask"
          />
          <InputComponent
            v-model.trim="form.email.value"
            @input="saveToLocalStorage"
            :error="form.email.error"
            title="E-Posta Adresiniz"
            placeholder="E-Posta Adresiniz"
          />
          <SelectComponent
            v-model="form.country_id.value"
            @input="saveToLocalStorage"
            :options="countries"
            :errors="form.country_id.error ? [form.country_id.error] : []"
            label-name="title"
            title="Russia"
            selectTitle="Ülke"
          />
          <InputComponent
            v-model.trim="form.tr_passport.value"
            @input="saveToLocalStorage"
            :error="form.tr_passport.error"
            title="Kimlik No"
            placeholder="Z20017989"
          />
          <InputComponent
            v-model.trim="form.tr_tax.value"
            @input="saveToLocalStorage"
            :error="form.tr_tax.error"
            title="Vergi Dairesi"
            placeholder="Vergi Dairesi"
          />
          <InputComponent
            v-model.trim="form.tr_city.value"
            @input="saveToLocalStorage"
            :error="form.tr_city.error"
            title="İl"
            placeholder="İl"
          />
          <InputComponent
            v-model.trim="form.tr_district.value"
            @input="saveToLocalStorage"
            :error="form.tr_district.error"
            title="İlçe"
            placeholder="İlçe"
          />
          <InputComponent
            v-model.trim="form.tr_store.value"
            @input="saveToLocalStorage"
            :error="form.tr_store.error"
            title="Şirket Ticari Ünvanı"
            placeholder="Şirket Ticari Ünvanı"
          />
          <InputComponent
            v-model.trim="form.title.value"
            @input="saveToLocalStorage"
            :error="form.title.error"
            title="Mağaza Adı"
            placeholder="Mağaza Adı"
          />
        </template>
        <template v-if="activeTab === 2">
          <InputComponent
            v-model.trim="form.tr_billing_address.value"
            @input="saveToLocalStorage"
            :error="form.tr_billing_address.error"
            title="Fatura Adresi"
            placeholder="Fatura Adresi"
          />
          <InputComponent
            v-model.trim="form.tr_delivery_address.value"
            @input="saveToLocalStorage"
            :error="form.tr_delivery_address.error"
            title="Sevkiyat Depo Adresi"
            subtitle="Adres hesap adresiyle eşleşiyorsa bu alanı boş bırakabilirsiniz"
            placeholder="Sevkiyat Depo Adresi"
          />
          <InputComponent
            v-model.trim="form.tr_invoice_type.value"
            @input="saveToLocalStorage"
            :error="form.tr_invoice_type.error"
            title="Fatura Türü"
            placeholder="Kağıt Fatura"
          />
          <InputComponent
            v-model.trim="form.tr_kep.value"
            @input="saveToLocalStorage"
            :error="form.tr_kep.error"
            title="KEP Adresi"
            placeholder="KEP Adresi"
          />
          <InputComponent
            v-model.trim="form.tr_mersis.value"
            @input="saveToLocalStorage"
            :error="form.tr_mersis.error"
            title="MERSİS No"
            placeholder="MERSİS No"
          />
          <InputComponent
            v-model.trim="form.tr_iban.value"
            @input="saveToLocalStorage"
            :error="form.tr_iban.error"
            title="IBAN Numarası"
            subtitle="şirket adıyla eşleşmelidir."
            placeholder="IBAN Numarası"
          />
          <InputComponent
            v-model.trim="form.tr_cfo.value"
            @input="saveToLocalStorage"
            :error="form.tr_cfo.error"
            title="Ad Soyad"
            placeholder="Ad Soyad"
          />
          <InputComponent
            v-model.trim="form.tr_cfo_email.value"
            @input="saveToLocalStorage"
            :error="form.tr_cfo_email.error"
            title="E-Mail Adresi"
            placeholder="E-Mail Adresi"
          />
          <InputComponent
            v-model.trim="form.tr_cfo_phone.value"
            @input="saveToLocalStorage"
            :error="form.tr_cfo_phone.error"
            title="Telefon Numarası"
            placeholder="Telefon Numarası"
            :mask="phoneMask"
          />
          <InputComponent
            v-model.trim="form.tr_coo.value"
            @input="saveToLocalStorage"
            :error="form.tr_coo.error"
            title="Müşteri Hizmetleri ve Operasyon Sorumlusu"
            placeholder="Müşteri Hizmetleri ve Operasyon Sorumlusu"
          />
          <InputComponent
            v-model.trim="form.tr_coo_email.value"
            @input="saveToLocalStorage"
            :error="form.tr_coo_email.error"
            title="E-Mail Adresi"
            placeholder="E-Mail Adresi"
          />
          <InputComponent
            v-model.trim="form.tr_coo_phone.value"
            @input="saveToLocalStorage"
            :error="form.tr_coo_phone.error"
            title="Telefon Numarası"
            placeholder="Telefon Numarası"
            :mask="phoneMask"
          />
          <FileDropInput
            @files-selected="handleFiles"
            title="Belge"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation"
            :error-messages="fileInputErrorMessages"
            :max-files="10"
            :max-size="25 * 1024 * 1024"
          >
            <template v-slot:prompt>
              <span>Dosyayı buraya sürükleyin veya</span>
              <span style="color: var(--link); text-decoration: underline">bilgisayarında bul</span>
            </template>
            <template v-slot:message>
              <span>Desteklenen formatlar: pptx, docx, xlsx</span>
              <span>Maksimum boyut: 25mb</span>
            </template>
          </FileDropInput>
        </template>
        <CheckboxComponent :checked="agree" @change="agree = !agree">
          <div>
            İşlem platformunun
            <a href="/files/terms.pdf" target="_blank">Kullanım Kuralları</a> ve iade
            <a href="/files/terms.pdf" target="_blank">kurallarını kabul ediyorum</a>
          </div>
        </CheckboxComponent>
        <button type="submit" class="btn btn--lg btn--main" :disabled="!agree">
          <FileIconComponent v-if="loading" name="loading" />
          <template>{{ loadingInfo || "Bir istek gönder" }}</template>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import FileIconComponent from "components/FileIconComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import CLAIM_SHOP_CREATE from "@/graphql/mutations/ClaimShopCreate.graphql";
import FILES_UPLOAD from "@/graphql/mutations/FilesUpload.graphql";
import SwitchButtonComponent from "components/inputs/SwitchButtonComponent.vue";
import FileDropInput from "components/inputs/FileDropInput.vue";

export default {
  name: "MarketplaceClaimCreateComponent",
  data() {
    return {
      loading: false,
      loadingInfo: null,
      agree: true,
      activeTab: 1,
      tabs: [
        {
          code: 1,
          title: "Temel bilgiler",
        },
        {
          code: 2,
          title: "Ek Bilgiler",
        },
      ],
      files: [],
      fileInputErrorMessages: {
        size: "Dosya boyutu izin verilen boyutu aşıyor",
        type: "Bu dosya uzantısı geçerli değil",
        maxFiles: "Maksimum dosya sayısına ulaşıldı",
      },
      country: "ru",
      form: {
        documents: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        category_id: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        title: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        inn: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        fio: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        phone: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        city_id: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        country_id: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_passport: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_tax: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_name: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_surname: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_city: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_district: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_store: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_billing_address: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_delivery_address: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_invoice_type: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_kep: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_mersis: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_iban: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_cfo: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_cfo_email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_cfo_phone: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_coo: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_coo_email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        tr_coo_phone: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  computed: {
    cities() {
      return this.$store.state.cities || [];
    },
    countries() {
      return this.$store.state.countries || [];
    },
    categories() {
      return this.$store.state.categories || [];
    },
    phoneMask() {
      const phone = this.form.phone.value;
      if (phone && phone.length > 1) {
        return phone.startsWith("+7") || phone.startsWith("+8")
          ? "+7 (###) ### - ## - ##"
          : "+90 (###) ### - ## - ##";
      }
      return "+############";
    },
  },
  mounted() {
    this.setFromStorage();
  },
  methods: {
    handleFiles(files) {
      this.files = files;
    },
    saveToLocalStorage() {
      localStorage.setItem("claim_form", JSON.stringify(this.form));
    },
    resetStorage() {
      localStorage.removeItem("claim_form");
    },
    setFromStorage() {
      if (localStorage.getItem("claim_form")) {
        this.form = JSON.parse(localStorage.getItem("claim_form"));
      }
      this.country = this.$route.query?.lang;
    },
    async uploadFiles() {
      this.loadingInfo = "Dosya yükleme";
      if (this.country === "tr") {
        this.loadingInfo = "Başvuru gönderme";
      }
      await this.$apollo
        .mutate({
          mutation: FILES_UPLOAD,
          variables: {
            files: this.files,
          },
        })
        .then(({ data }) => {
          if (data.FilesUpload && data.FilesUpload.length) {
            this.form.documents.value = data.FilesUpload;
          }
        });
    },
    async claimCreate() {
      if (!this.loading && this.agree) {
        this.loading = true;
        this.resetErrors("form");
        if (this.files.length) {
          await this.uploadFiles();
        }
        this.loadingInfo = "Отправка заявки";
        let variables = {
          category_id: this.form.category_id.value ? this.form.category_id.value.id : null,
          city_id: this.form.city_id.value ? this.form.city_id.value.id : null,
          title: this.form.title.value,
          inn: this.form.inn.value,
          fio: this.form.fio.value,
          email: this.form.email.value,
          phone: this.form.phone.value ? "+" + this.form.phone.value.replace(/\D+/g, "") : null,
        };
        if (this.country === "tr") {
          this.loadingInfo = "Başvuru gönderme";
          variables = {
            country_type: this.country,
            email: this.form.email.value,
            phone: this.form.phone.value ? "+" + this.form.phone.value.replace(/\D+/g, "") : null,
            title: this.form.title.value,
            country_id: this.form.country_id.value ? this.form.country_id.value.id : null,
            tr_passport: this.form.tr_passport.value,
            tr_tax: this.form.tr_tax.value,
            tr_name: this.form.tr_name.value,
            tr_surname: this.form.tr_surname.value,
            tr_city: this.form.tr_city.value,
            tr_district: this.form.tr_district.value,
            tr_store: this.form.tr_store.value,
            tr_billing_address: this.form.tr_billing_address.value,
            tr_delivery_address: this.form.tr_delivery_address.value,
            tr_invoice_type: this.form.tr_invoice_type.value,
            tr_kep: this.form.tr_kep.value,
            tr_mersis: this.form.tr_mersis.value,
            tr_iban: this.form.tr_iban.value,
            tr_cfo: this.form.tr_cfo.value,
            tr_cfo_email: this.form.tr_cfo_email.value,
            tr_cfo_phone: this.form.tr_cfo_phone.value
              ? "+" + this.form.tr_cfo_phone.value.replace(/\D+/g, "")
              : null,
            tr_coo: this.form.tr_coo.value,
            tr_coo_email: this.form.tr_coo_email.value,
            tr_coo_phone: this.form.tr_coo_phone.value
              ? "+" + this.form.tr_coo_phone.value.replace(/\D+/g, "")
              : null,
          };
        }
        await this.$apollo
          .mutate({
            mutation: CLAIM_SHOP_CREATE,
            variables: variables,
          })
          .then(({ data }) => {
            if (data && data.ClaimShopCreate) {
              this.resetForm("form");
              this.$notify({
                title: "Отправлено",
                text: data.ClaimShopCreate.message,
                duration: 5000,
                speed: 200,
                type: "success",
              });
              this.resetStorage();
              this.loadingInfo = null;
            }
            this.loading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.loadingInfo = null;
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
    resetErrors(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].error = undefined;
      });
    },
    resetForm(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].value = undefined;
      });
    },
  },
  components: {
    FileDropInput,
    SwitchButtonComponent,
    SelectComponent,
    FileIconComponent,
    CheckboxComponent,
    InputComponent,
  },
};
</script>

<style lang="stylus">
.marketplace-claim {
  display flex
  flex-direction column
  align-items center
  width 100%
  gap 24px
  max-width 856px
  background: var(--white)
  border-radius: 24px;
  padding 48px 0
  position relative

  &__title {
    margin 0
  }

  &__switch {
    position absolute
    right 16px
    top 16px
  }

  &__tabs {
    display flex
    width 100%
    overflow-x auto
    max-width 500px
  }

  &__tab {
    flex 1 0 auto
    background none
    border none
    padding 18px 25px
    border-bottom 1px solid var(--gray-100)
    color var(--gray-100)
    font-size: 0.875em
    line-height: 20px;
    cursor pointer
    transition var(--transition)

    &:hover {
      background var(--blue-200)
    }

    &--active {
      border-bottom 1px solid var(--accent)
      color var(--accent)
    }
  }

  &__container {
    display flex
    justify-content center
    width 100%
    padding 0 15px
  }

  &__form {
    max-width 375px
    border none
    padding 0

    .btn {
      justify-content center
    }
  }
}
</style>
