<template>
  <div class="drop-zone-input">
    <div class="drop-zone-input__header">
      <span v-if="title" class="drop-zone-input__title">{{ title }}</span>
      <div class="drop-zone-input__error" v-if="error">
        {{ error }}
      </div>
    </div>
    <div
      class="drop-zone-input__field"
      :class="{ 'drop-zone-input__field--over': isDragging }"
      @click="triggerFileInput"
      @dragover.prevent="toggleDragState(true)"
      @dragleave="toggleDragState(false)"
      @dragend="toggleDragState(false)"
      @drop.prevent="onDrop"
    >
      <RemixIconComponent category="Document" name="file-upload-line" />
      <div v-if="!hasFiles" class="drop-zone-input__prompt">
        <slot name="prompt">Загрузка файлов</slot>
      </div>
      <div class="drop-zone-input__files">
        <div class="drop-zone-input__thumb" v-for="(file, index) in files" :key="file.id">
          <span class="drop-zone-input__file-name">{{ file.name }}</span>
          <button class="drop-zone-input__remove" @click.stop="removeFile(index)" title="Remove file">
            <RemixIconComponent category="System" name="close-line" />
          </button>
        </div>
      </div>
      <input
        ref="fileInput"
        type="file"
        class="drop-zone-input__input"
        @change="onFileChange"
        :accept="accept"
        :multiple="maxFiles > 1"
      />
    </div>
    <div class="drop-zone-input__message">
      <slot name="message" />
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "FileDropInput",
  components: { RemixIconComponent },
  props: {
    title: String,
    accept: {
      type: String,
      default: "",
    },
    initial: Array,
    // Maximum file size in bytes
    maxSize: {
      type: Number,
      default: 5 * 1024 * 1024, // 5MB default
    },
    // Maximum number of files
    maxFiles: {
      type: Number,
      default: 5,
    },
    // Custom error messages
    errorMessages: {
      type: Object,
      default: () => ({
        size: "Размер файла превышает допустимый",
        type: "Расширение файла недопустимо",
        maxFiles: "Достигнуто максимальное количество файлов",
      }),
    },
  },
  data() {
    return {
      isDragging: false,
      files: [],
      thumbnails: {},
      error: null,
    };
  },
  created() {
    if (this.initial) {
      this.files = this.initial;
    }
  },
  computed: {
    hasFiles() {
      return this.files.length > 0;
    },

    isMaxFilesReached() {
      return this.files.length >= this.maxFiles;
    },
  },
  methods: {
    triggerFileInput() {
      if (this.isMaxFilesReached) {
        return;
      }
      this.$refs.fileInput.click();
    },
    toggleDragState(isDragging) {
      this.isDragging = isDragging;
    },
    async onFileChange(event) {
      const newFiles = Array.from(event.target.files);
      await this.processFiles(newFiles);
      event.target.value = ""; // Reset input
    },
    async onDrop(event) {
      const newFiles = Array.from(event.dataTransfer.files);
      await this.processFiles(newFiles);
      this.toggleDragState(false);
    },
    async processFiles(newFiles) {
      this.error = null;

      // Check max files limit
      if (this.files.length + newFiles.length > this.maxFiles) {
        this.error = this.errorMessages.maxFiles;
        this.$emit("error", { type: "maxFiles", message: this.error });
        return;
      }

      for (const file of newFiles) {
        const validationError = this.validateFile(file);
        if (validationError) {
          this.error = validationError.message;
          this.$emit("error", validationError);
          continue;
        }

        // Add unique ID to file for key purposes
        const fileWithId = Object.assign(file, { id: Date.now() + Math.random() });
        this.files.push(fileWithId);

        if (file.type.startsWith("image/")) {
          this.thumbnails[fileWithId.id] = await this.readFileAsDataURL(file);
        }
      }

      this.$emit("files-selected", this.files);
    },
    validateFile(file) {
      if (file.size > this.maxSize) {
        return { type: "size", message: this.errorMessages.size };
      }

      if (this.accept && !this.isFileTypeValid(file)) {
        return { type: "type", message: this.errorMessages.type };
      }

      return null;
    },
    isFileTypeValid(file) {
      if (!this.accept) return true;

      const acceptedTypes = this.accept.split(",").map((type) => type.trim());

      return acceptedTypes.some((type) => {
        if (type.endsWith("/*")) {
          const baseType = type.slice(0, -2);
          return file.type.startsWith(baseType);
        }
        return file.type === type;
      });
    },
    removeFile(index) {
      const file = this.files[index];
      delete this.thumbnails[file.id];
      this.files.splice(index, 1);
      this.$emit("file-removed", file);
      this.$emit("files-selected", this.files);
    },
    readFileAsDataURL(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
    },
    formatFileSize(bytes) {
      const units = ["B", "KB", "MB", "GB"];
      let size = bytes;
      let unitIndex = 0;

      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
      }

      return `${size.toFixed(1)} ${units[unitIndex]}`;
    },
  },
};
</script>

<style lang="stylus">
.drop-zone-input {
  display flex
  flex-direction column
  gap 5px

  &__header {
    display flex
    justify-content space-between
    width 100%
    gap 5px
  }

  &__title {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: var(--body-color-muted);
  }

  &__error {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 20px;
    color: var(--red-600);
  }

  &__message {
    display flex
    justify-content space-between
    width 100%
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 12px;
    color: var(--gray-100);
  }

  &__field {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction column
    text-align: center;
    gap: 10px;
    font-weight: 500;
    font-size: 1.25rem;
    cursor: pointer;
    border: 1px dashed var(--gray-50);
    position relative
    padding: 20px;

    &--over {
      border-style: solid;
    }
  }

  &__prompt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: var(--body-color-muted);
  }

  &__input {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    position absolute
  }

  &__files {
    display flex
    flex-direction column
    width 100%
    gap 2px
  }

  &__remove {
    background none
    border none
    display inline-flex
    cursor pointer

    .icon {
      width 18px
      height 18px

      svg path {
        fill var(--body-color-muted)
      }
    }
  }

  &__thumb {
    width: 100%;
    position: relative;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 20px;
    color: var(--body-color-muted);
    display inline-flex
    justify-content center
    align-items center
    gap 10px
  }

  .Document-file-upload-line-icon {
    width: 34px;
    height: 34px;

    svg path {
      fill var(--body-color-muted)
    }
  }
}
</style>
