<template>
  <label class="switch-button" :class="{ 'switch-button--active': checked }">
    <span class="switch-button__container">
      <input type="checkbox" :checked="checked" @change="$emit('change', $event.target.checked)" />
      <span class="switch-button__handle"></span>
    </span>
    <slot />
  </label>
</template>

<script>
export default {
  name: "SwitchButtonComponent",
  props: {
    checked: Boolean,
  },
  model: {
    prop: "checked",
    event: "change",
  },
};
</script>

<style lang="stylus">
.switch-button {
  position relative
  display inline-flex
  align-items center
  gap 10px
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: var(--black);
  cursor pointer

  &--active &__handle {
    translate 24px 0
  }

  &--active &__container {
    background var(--accent)
  }

  &__container {
    display inline-flex
    height 22px
    width 46px
    border-radius 100px
    background var(--gray)
    padding 2px
    transition .2s
  }

  &__handle {
    background: var(--white);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    border-radius: 100px;
    width 18px
    height 18px
    transition .2s
  }

  [type="checkbox"] {
    width 0
    height 0
    opacity 0
    visibility hidden
    position absolute
    overflow hidden
  }
}
</style>
